import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildDataFileGroupFilesUrl, buildHeaderFileGroupFilesUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const uploadFile = (
    type: ACTIVITY_TYPES,
    fileObject: any,
    header = false,
): Observable<any> => {
    const url = header
        ? buildHeaderFileGroupFilesUrl(fileObject.dataFileGroupId)
        : buildDataFileGroupFilesUrl(fileObject.dataFileGroupId, type);
    const file = { file: fileObject.file };
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, file).pipe(
        switchMap((result: any) => {
            const converted = !result.ok ? result : convertToCamel(result);
            return observableOf({ file: converted, name: fileObject.file.name });
        }),
    );
};
